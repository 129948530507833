"use client";

import { FC, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import styles from '@/styles/Home.module.css'
import classNames from 'classnames';
import Button from '@apg.gg/core/lib/Button';
import { ApgUser } from '@/domain/account.interface';
import { usePathname } from 'next/navigation';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next-nprogress-bar';
import { cn } from '@apg.gg/core/lib/cn';
import RichText from '@/ui/RichText';

interface LayoutProfileProps {
  children: React.ReactNode;
  locale: string;
  userProfile?: ApgUser | null;
}

const LayoutProfile: FC<LayoutProfileProps> = ({
  children,
  locale,
}) => {
  const router = useRouter();
  const pathname = usePathname();
  const t = useTranslations("Common");

  const [redirect] = useState<string>(pathname.includes('/auth/') ? '/community' : pathname);

  const mainClasses = pathname.includes('/auth') ? "flex flex-col items-center justify-center gradient-4" : "";

  const classNamesMain = cn(
    "px-4 w-full h-[calc(100vh-56px)] overflow-y-auto scroll-smooth",
    mainClasses,
    pathname === `/${locale}` ? 'px-0' : ''
  );

  return (
    <>
      <main className={classNames(
        styles.main,
        "scrollbar-trigger"
      )}>
        <nav className="bg-black shadow-lg w-full z-50 flex items-center justify-start gap-2 px-4 py-2 h-14">
          <div className="flex items-center justify-start flex-1">
            <div className="flex h-full">
              <Link href="/">
                <Image src="/assets/resources/isotipo-white.png" alt='APG.gg' className='h-10 w-auto' width={162} height={61} />
              </Link>
            </div>
          </div>

          <div className="flex gap-2 ml-auto">
            <Button className="navbar-login-btn" onClick={() => router.push(`/${locale}/auth/login?redirect=${redirect}`)} type="primary">
              {t("navbar.login-btn")}
            </Button>
            <Button className="navbar-register-btn" onClick={() => router.push(`/${locale}/auth/sign-up?redirect=${redirect}`)} type="outline">
              {t("navbar.register-btn")}
            </Button>
          </div>
        </nav>
        <div className={classNamesMain}>
          {children}
        </div>
      </main>

      <div className="w-full absolute bottom-0 inset-x-0 py-2 bg-blue z-50 hidden sm:block">
        <div className="container">
          <div className="flex h-12 items-center px-4 gap-4">
            <div className="flex flex-1">
              <h3 className="text-title-sm">
                <RichText>
                  {
                    t.rich('join-community-connect-with-gamers', {
                      strong: (chunks) => <strong>{chunks}</strong>
                    })
                  }
                </RichText>
              </h3>
            </div>
            <div className="flex gap-2 ml-auto">
              <Button className="navbar-login-btn border-white text-white" onClick={() => router.push(`/${locale}/auth/login?redirect=${redirect}`)} type="outline">
                {t("navbar.login-btn")}
              </Button>
              <Button className="navbar-register-btn" onClick={() => router.push(`/${locale}/auth/sign-up?redirect=${redirect}`)} type="ghost">
                {t("navbar.register-btn")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LayoutProfile;